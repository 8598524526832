import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route ,Router} from "react-router-dom";
import React from "react";
import { Suspense } from "react";

import i18next from 'i18next';
import { useTranslation, initReactI18next} from "react-i18next";
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';


import "./index.css"
// import Layout from "./pages/Layout";
// import Home from "./pages/Home";

// import Media from "./pages/Media";
// import ProductsPage from "./pages/ProductsPage";
// import NoPage from "./pages/NoPage";
// import Products from "./pages/Products"
// import ProductPage from "./pages/productscomp/ProductPage"
// import BlogPage from "./pages/BlogPage";

import { ThemeProvider } from "@material-tailwind/react";
// import BlogLayout from "./pages/BlogLayout";
// import Jobs from "./pages/Jobs";
// import JobSpec from "./pages/jobsComp/JobSpec";
// import DirContact from "./pages/DirContact";

const Layout = React.lazy( () => import("./pages/Layout"))
const Home = React.lazy( () => import("./pages/Home"))
const Media = React.lazy( () => import("./pages/Media"))
const ProductsPage = React.lazy( () => import("./pages/ProductsPage"))
const NoPage = React.lazy( () => import("./pages/NoPage"))
const Products = React.lazy( () => import("./pages/Products"))
const ProductPage = React.lazy( () => import("./pages/productscomp/ProductPage"))
const BlogPage = React.lazy( () => import("./pages/BlogPage"))
const BlogLayout = React.lazy( () => import("./pages/BlogLayout"))
const Jobs = React.lazy( () => import("./pages/Jobs"))
const JobSpec = React.lazy( () => import("./pages/jobsComp/JobSpec"))
const DirContact = React.lazy( () => import("./pages/DirContact"))

// import Blogs from "./pages/Blogs";
const LazyBlogs = React.lazy( () => import("./pages/Blogs"))
// import Contact from "./pages/Contact";
const LazyContact = React.lazy( () => import("./pages/Contact"))
// import About from "./pages/About";
const LazyAbout = React.lazy( () => import("./pages/About"))
const ScrollToTop = React.lazy( () => import("./pages/global/ScrollTo"))


i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
  //lng: 'en', // if you're using a language detector, do not define the lng option
  debug: true,
  supportedLngs: ['en','ar', 'fr','de'],
  fallbackLng: 'en',
  detection:{
    order: ['path', 'cookie','htmlTag', 'sessionStorage', 'navigator',  'subdomain'],
    caches: ['cookie'],
  }, 
  backend: {
    loadPath: '/assets/lang/{{lng}}/translation.json',
  }
});



export default function App() {

  const ip = 'https://new.chimiart.com/';

  const loadingMarkUp = (
    <div className=" mt-[100px] text-center flex flex-col w-full h-full items-center justify-center">
      <h1 className="mb-20 font-extrabold text-3xl text-chimib-600">Welcome To CHIMIART</h1>
      <h2>Loading...</h2>
    </div>
  )
  return (
    

    <ThemeProvider>
    <BrowserRouter>

    <React.Suspense fallback={loadingMarkUp}>
        <ScrollToTop/>
        </React.Suspense>
        
      <Routes>

      

        <Route path="/" element={
        <React.Suspense fallback={loadingMarkUp}>
        <Layout  ip={ip} />
      </React.Suspense>
      }>
          <Route index element={
              <React.Suspense fallback={loadingMarkUp}>
              <Home ip={ip}/>
            </React.Suspense>
            } />

          <Route path="about" element={
            <React.Suspense fallback={loadingMarkUp}>
              <LazyAbout />
            </React.Suspense>} />
          <Route path="gallery/" element={
            <React.Suspense fallback={loadingMarkUp}>
              <Media />
            </React.Suspense>
            } />

          <Route path="contact" element={
            <React.Suspense fallback={loadingMarkUp}>
              <LazyContact />
            </React.Suspense>} >
            <Route index element={
            
            <React.Suspense fallback={loadingMarkUp}>
              <DirContact/>
            </React.Suspense>
           }/>
            <Route path="careers" element={
            
            <React.Suspense fallback={loadingMarkUp}>
              <Jobs/>
            </React.Suspense>
            }/>
            <Route path="careers/:job" element={
            <React.Suspense fallback={loadingMarkUp}>
              <JobSpec ip={ip}/>
            </React.Suspense>
            }/>
          </Route>
          
          <Route path="*" element={
            <React.Suspense fallback={loadingMarkUp}>
             <NoPage />
            </React.Suspense>
            } />
        </Route>

        <Route path="/products" element={
            <React.Suspense fallback={loadingMarkUp}>
               <Products ip={ip}/>
            </React.Suspense>
           } >
          <Route index element={
          
          <React.Suspense fallback={loadingMarkUp}>
          <ProductsPage ip={ip} />
        </React.Suspense>
        }/>
          <Route path=":section" element={
            <React.Suspense fallback={loadingMarkUp}>
              <ProductsPage ip={ip} />
            </React.Suspense>
            }/>
          <Route path=":section/:category" element={
            <React.Suspense fallback={loadingMarkUp}>
            <ProductsPage ip={ip}/>
            </React.Suspense>} />

          <Route path=":section/:category/:product" element={
          
          <React.Suspense fallback={loadingMarkUp}>
          <ProductPage ip={ip}/>
        </React.Suspense>
        } />
        </Route>
        
        
          <Route path="/blogs"  element={
            <React.Suspense fallback={loadingMarkUp}>
              <BlogLayout ip={ip}/>
            </React.Suspense>
            }>
            <Route index element={
            <React.Suspense fallback='Loading...'>
              <LazyBlogs  ip={ip}/>
            </React.Suspense>}/>
            <Route path=":article" element={
            <React.Suspense fallback={loadingMarkUp}>
              <BlogPage ip={ip} />
            </React.Suspense>
            }/>
          </Route>

      </Routes>
    </BrowserRouter>
    </ThemeProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
